import React, { useState } from 'react';
import style from './checkout.module.scss';


export interface FooterData {
    aboutUs: {
      text: string;
      link?: string;
    };
    privacy: {
      text: string;
      link?: string;
    };
    faq: {
      text: string;
      link?: string;
    };
    tAndC: {
      text: string;
      link?: string;
    };
    contactUs: string;
    email: string;
    copyright: string;
    address: string;
  };

export const defaultFooterData = {
    aboutUs: {
      text: "",
    },
    privacy: {
      text: "",
    },
    faq: {
      text: "",
    },
    tAndC: {
      text: "",
    },
    contactUs: "",
    email: "",
    copyright: "",
    address: "",
  };

export const Footer = ({termsAndConditions, privacyAndPolicy, contact,footerData}: {termsAndConditions?:string, privacyAndPolicy?:string , contact?:string,footerData?: FooterData}) => {
  const [contactUsModal, setContactUsModal] = useState(false);
    
  return (<>
     {contactUsModal && (
            <div className={style["contact-us-modal"]}>
              <div
                className={style["backdrop"]}
                onClick={() => setContactUsModal(false)}
              />
              <div className={style["contact-us-container"]}>
                <div className={style["cross-icon-container"]}>
                  <div
                    className={style["cross-icon"]}
                    onClick={() => {
                      setContactUsModal(false);
                    }}
                  >
                    X
                  </div>
                </div>
                <div className={style["contact-us-heading"]}>Contact Us</div>
                <hr />
                <div className={style["contact-us-body"]}>
                  <div className={style["contact-us-body-left"]}>
                    <div className={style["contact-us-left-item"]}>
                      Contact Number : 
                    </div>
                    <div className={style["contact-us-left-item"]}>
                      Email : 
                    </div>
                    <div
                      className={style["contact-us-left-item"]}
                      style={{ lineHeight: "2" }}
                    >
                      Address :
                    </div>
                  </div>
                  <div className={style["contact-us-body-right"]}>
                    <div className={style["contact-us-right-item"]}>
                    {footerData?.contactUs}
                    </div>
                    <div className={style["contact-us-right-item"]}>
                    {footerData?.email}
                    </div>
                    <div
                      className={style["contact-us-right-item"]}
                      style={{ lineHeight: "2" }}
                    >
                      {footerData?.address}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
    <div className={style['footer-container']}>
      <div className={style['footer-text']}>{footerData?.copyright || "Copyright © 2024"}</div>
      <div className={style['footer-hanldes']}>
        { footerData?.faq?.link && <a className={style['footer-text']} href={footerData?.faq?.link} target='blank' rel="noopener noreferrer">FAQs</a>}
        <span className={style['footer-text']} onClick={() => setContactUsModal(true)}>Contact Us</span>
        {footerData?.aboutUs?.link && <a className={style['footer-text']} href={footerData?.aboutUs?.link} target='blank' rel="noopener noreferrer">About Us</a>}
        <a className={style['footer-text']} href={footerData?.privacy?.link || privacyAndPolicy} target='blank' rel="noopener noreferrer">Privacy Policy</a>
        <a className={style['footer-text']} href={footerData?.tAndC?.link || termsAndConditions} target='blank' rel="noopener noreferrer">Terms and Conditions</a>
      </div>
    </div>
  </>);
};
